import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import quotes from "../content/quotes"

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.org/docs/use-static-query/
 */

const QuoteImage = ({ author }) => {
  // console.log(author)

  // if (!author) {
  //   return <></>
  // }

  const data = useStaticQuery(graphql`
    query {
      albert: file(relativePath: { eq: "testimonials/albert.jpg" }) {
        childImageSharp {
          fluid {
            aspectRatio
            base64
            src
            sizes
            srcSet
          }
        }
      }

      benjamin: file(relativePath: { eq: "testimonials/benjamin.jpg" }) {
        childImageSharp {
          fluid {
            aspectRatio
            base64
            src
            sizes
            srcSet
          }
        }
      }

      caesar: file(relativePath: { eq: "testimonials/caesar.jpg" }) {
        childImageSharp {
          fluid {
            aspectRatio
            base64
            src
            sizes
            srcSet
          }
        }
      }

      darwin: file(relativePath: { eq: "testimonials/darwin.jpg" }) {
        childImageSharp {
          fluid {
            aspectRatio
            base64
            src
            sizes
            srcSet
          }
        }
      }

      eratosthenes: file(
        relativePath: { eq: "testimonials/eratosthenes.jpg" }
      ) {
        childImageSharp {
          fluid {
            aspectRatio
            base64
            src
            sizes
            srcSet
          }
        }
      }

      frost: file(relativePath: { eq: "testimonials/frost.jpg" }) {
        childImageSharp {
          fluid {
            aspectRatio
            base64
            src
            sizes
            srcSet
          }
        }
      }

      galileo: file(relativePath: { eq: "testimonials/galileo.jpg" }) {
        childImageSharp {
          fluid {
            aspectRatio
            base64
            src
            sizes
            srcSet
          }
        }
      }

      homer: file(relativePath: { eq: "testimonials/homer.jpg" }) {
        childImageSharp {
          fluid {
            aspectRatio
            base64
            src
            sizes
            srcSet
          }
        }
      }

      nero: file(relativePath: { eq: "testimonials/nero.jpg" }) {
        childImageSharp {
          fluid {
            aspectRatio
            base64
            src
            sizes
            srcSet
          }
        }
      }

      noah: file(relativePath: { eq: "testimonials/noah.jpg" }) {
        childImageSharp {
          fluid {
            aspectRatio
            base64
            src
            sizes
            srcSet
          }
        }
      }

      suntzu: file(relativePath: { eq: "testimonials/suntzu.jpg" }) {
        childImageSharp {
          fluid {
            aspectRatio
            base64
            src
            sizes
            srcSet
          }
        }
      }

      tj: file(relativePath: { eq: "testimonials/tj.jpg" }) {
        childImageSharp {
          fluid {
            aspectRatio
            base64
            src
            sizes
            srcSet
          }
        }
      }
    }
  `)

  const fluid = data[author].childImageSharp.fluid

  return <Img fluid={fluid} />
}

export default QuoteImage
